import { trpc } from '../utils/trpc';
import { useSession } from 'next-auth/react';

export const useMe = () => {
  const { data: session } = useSession();
  const {
    data: me,
    refetch,
    isLoading,
    isFetching,
    isInitialLoading,
  } = trpc.users.getMe.useQuery(undefined, {
    refetchOnWindowFocus: true,
    enabled: !!session,
    useErrorBoundary: true,
    trpc: {
      context: {
        skipBatch: true,
      },
    },
  });
  const isLoadingMe = isLoading || isFetching;
  return {
    me,
    session,
    refetch,
    isLoadingMe,
    isInitialLoading,
  };
};

import Analytics from 'analytics';
import { AnalyticsProvider } from 'use-analytics';
import { createId } from '@paralleldrive/cuid2';

type CarketaAnalyticsPluginSessionConfig = {
  session_id: string;
};

type AnalyticsPostFields = {
  anonymous_id: string;
  session_id: string;
  component: string;
  event: string;
  url: string;
  payload: any;
  custom: any;
  created_at: Date;
};

const analyticsPost = async (fields: AnalyticsPostFields) => {
  return fetch('/api/analytics', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(fields),
  }).then((response) => response.json());
};

function carketaAnalyticsPlugin() {
  let session_id = createId();
  if (sessionStorage) {
    const storedSessionId = sessionStorage.getItem(
      'carketa-analytics-session-id'
    );
    if (storedSessionId) {
      session_id = storedSessionId;
    } else {
      sessionStorage.setItem('carketa-analytics-session-id', session_id);
    }
  }
  // return object for analytics to use
  return {
    /* All plugins require a name */
    name: 'carketa-analytics-plugin',
    /* Everything else below this is optional depending on your plugin requirements */
    config: {
      session_id,
    },
    // initialize: ({
    //   config,
    // }: {
    //   config: CarketaAnalyticsPluginSessionConfig;
    // }) => {
    //   // load provider script to page
    //   // console.log('initialize', config);
    // },
    page: ({
      payload,
      config,
    }: {
      payload: any;
      config: CarketaAnalyticsPluginSessionConfig;
    }) => {
      // console.log('page', payload, config);
      analyticsPost({
        anonymous_id: payload.anonymousId,
        session_id: config.session_id,
        component: payload.options?.component || '',
        event: 'page',
        url: window.location.href,
        payload: payload,
        custom: payload.options?.custom || {},
        created_at: new Date(payload.meta.ts),
      });
    },
    track: ({
      payload,
      config,
    }: {
      payload: any;
      config: CarketaAnalyticsPluginSessionConfig;
    }) => {
      // console.log('track', payload, config);
      analyticsPost({
        anonymous_id: payload.anonymousId,
        session_id: config.session_id,
        component: payload.options?.component || '',
        event: payload.event,
        url: window.location.href,
        payload: payload,
        custom: payload.options?.custom || {},
        created_at: new Date(payload.meta.ts),
      });
    },
    // identify: ({ payload }: any) => {
    //   // call provider specific user identify method
    //   console.log('identify', payload);
    // },
    loaded: () => {
      // return boolean so analytics knows when it can send data to third-party
      return true;
    },
  };
}

interface AnalyticsWrapperProps {
  children: React.ReactNode;
}

export const AnalyticsWrapper: React.FC<AnalyticsWrapperProps> = ({
  children,
}) => {
  const analytics = Analytics({
    app: 'carketa',
    plugins: [carketaAnalyticsPlugin()],
  });
  return <AnalyticsProvider instance={analytics}>{children}</AnalyticsProvider>;
};

// Count page view
// const { page } = useAnalytics();
// useEffect(() => {
//   page();
//   // eslint-disable-next-line react-hooks/exhaustive-deps
// }, []);

// const Example = () => {
//   const { track, page, identify } = useAnalytics();
//   return (
//     <div>
//       <button
//         onClick={() =>
//           track('trackThing', { email: 'bob@bob.com', userId: 123 })
//         }
//       >
//         Track event
//       </button>
//       <button onClick={() => page()}>Trigger page view</button>
//       <button
//         onClick={() =>
//           identify('userID', { email: 'bob@bob.com', userId: 123 })
//         }
//       >
//         Trigger identify visitor
//       </button>
//     </div>
//   );
// };

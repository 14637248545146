import { Prisma } from '@prisma/client';
import { JsonValue } from '@prisma/client/runtime/library';

export enum SCOPE {
  create,
  read,
  update,
  delete,
}

export enum ROLE {
  VIEWER = 'VIEWER',
  EDITOR = 'EDITOR',
  OWNER = 'OWNER',
  USER = 'USER',
}

export enum USER_ROLE {
  USER = 'USER',
  OWNER = 'OWNER',
}

export const PERMISSION: { [key: string]: Array<SCOPE> } = {
  [ROLE.VIEWER]: [SCOPE.read],
  [ROLE.EDITOR]: [SCOPE.read, SCOPE.create, SCOPE.update],
  [ROLE.OWNER]: [SCOPE.create, SCOPE.read, SCOPE.update, SCOPE.delete],
};

export enum USER_TYPE {
  admin = 'admin',
  user = 'user',
  agent = 'agent',
}

export enum USER_GROUP {
  mechanic = 'mechanic',
  cpo = 'cpo',
  dev = 'dev',
}
//Add any feature flags to use here
export enum FEATURE_FLAG {
  everything = 'everything',
  recon = 'recon',
  pricing = 'pricing',
  dms_configuration = 'dms_configuration',
  cpo = 'cpo',
  sales_packet = 'sales_packet',
  reports = 'reports',
  request_inventory = 'request_inventory',
  checklist = 'checklist',
  recon_status = 'recon_status',
  appraisals = 'appraisals',
  carketa_connect = 'carketa_connect',
  carfax_comps = 'carfax_comps',
  market_report = 'market_report',
  sourcing = 'sourcing',
  ksl_comps = 'ksl_comps',
  ksl_dealer_insights = 'ksl_dealer_insights',
  territory_insights = 'territory_insights',
  account = 'account',
  none = 'none',
}
//These are the permissions for user access to certain parts of the app. Owner still has complete access.
export enum USER_ACCESS_PERMISSIONS {
  dashboard = 'dashboard',
  inventory = 'inventory',
  appraisals = 'appraisals',
  recon = 'recon',
  pricing = 'pricing',
  reports = 'reports',
  settings = 'settings',
  roapproval = 'roapproval',
  needs_attention = 'needs_attention',
  user_settings = 'user_settings',
  billing_settings = 'billing_settings',
  territory_insights_settings = 'territory_insights_settings',
  transportation = 'transportation',
}

//This is to show on Admin
export const FEATURE_FLAGS = [
  'everything',
  'recon',
  'pricing',
  'cpo',
  'sales_packet',
  'appraisals',
  'carketa_connect',
  'carfax_comps',
  'market_report',
  'sourcing',
  'market_pricing_data',
  'ksl_comps',
  'ksl_dealer_insights',
  'territory_insights',
  'account',
];

export enum BOOKS {
  MMR = 'MMR',
  KBB = 'KBB',
  JDPOWER = 'JDPower',
  BLACKBOOK = 'BlackBook',
}

// This is a key / value to say what permission requires what feature flag (product)
export const USER_ACCESS_PERMISSIONS_FEATURE_FLAGS: Record<
  USER_ACCESS_PERMISSIONS,
  FEATURE_FLAG
> = {
  [USER_ACCESS_PERMISSIONS.user_settings]: FEATURE_FLAG.account,
  [USER_ACCESS_PERMISSIONS.billing_settings]: FEATURE_FLAG.account,
  [USER_ACCESS_PERMISSIONS.dashboard]: FEATURE_FLAG.none,
  [USER_ACCESS_PERMISSIONS.inventory]: FEATURE_FLAG.none,
  [USER_ACCESS_PERMISSIONS.appraisals]: FEATURE_FLAG.none,
  [USER_ACCESS_PERMISSIONS.recon]: FEATURE_FLAG.none,
  [USER_ACCESS_PERMISSIONS.transportation]: FEATURE_FLAG.none,
  [USER_ACCESS_PERMISSIONS.pricing]: FEATURE_FLAG.none,
  [USER_ACCESS_PERMISSIONS.reports]: FEATURE_FLAG.none,
  [USER_ACCESS_PERMISSIONS.settings]: FEATURE_FLAG.none,
  [USER_ACCESS_PERMISSIONS.roapproval]: FEATURE_FLAG.none,
  [USER_ACCESS_PERMISSIONS.needs_attention]: FEATURE_FLAG.none,
  [USER_ACCESS_PERMISSIONS.territory_insights_settings]:
    FEATURE_FLAG.territory_insights,
};

type Me = {
  type?: USER_TYPE | string | null | undefined;
  role?: USER_ROLE | string | null | undefined;
  users_groups?: { group: { id: string; name: string } }[] | undefined;
  feature_flags?: JsonValue | undefined;
  organization?: any;
  permissions?:
    | {
        permissions: {
          name: string;
          id: string;
          feature_flag: string | null;
        } | null;
        user_id: string;
        permission_id: string | null;
      }[]
    | undefined;
  product_feature_flags: Array<string>;
};

export type PermissionProps = {
  me?: Me | null | undefined;
  children?: React.ReactNode;
  fallback?: React.ReactNode;
  types?: Array<USER_TYPE>;
  scopes?: Array<SCOPE>;
  roles?: Array<USER_ROLE>;
  groups?: Array<USER_GROUP>;
  excludeGroups?: Array<USER_GROUP>;
  feature_flags?: Array<string>;
  user_access?: Array<USER_ACCESS_PERMISSIONS>;
  all_permissions?: Array<USER_ACCESS_PERMISSIONS>;
  some_permissions?: Array<USER_ACCESS_PERMISSIONS>;
  all_feature_flags?: Array<FEATURE_FLAG>;
  some_feature_flags?: Array<FEATURE_FLAG>;
  unleash?: string;
};

export const hasPermission = ({
  me,
  types = [],
  roles = [],
  scopes = [],
  groups = [],
  excludeGroups = [],
  feature_flags = [],
  user_access = [],
  unleash = '',
  all_permissions = [],
  some_permissions = [],
  all_feature_flags = [],
  some_feature_flags = [],
}: PermissionProps) => {
  // Move unleash to the top to check first and fail early if they dont have the feature flag
  if (unleash) {
    const hasUnleash = me?.product_feature_flags?.some(
      (flag) => flag === unleash
    );
    if (!hasUnleash) return false;
  }

  // Check session
  if (!me) {
    return false;
  }

  // Check type if passed in
  if (types.length > 0 && types.indexOf(me.type as USER_TYPE) === -1) {
    return false;
  }

  // Check role if passed in
  if (roles.length && roles.indexOf(me.role as USER_ROLE) === -1) {
    return false;
  }

  // Check group if passed in
  if (groups.length) {
    const group_names =
      me?.users_groups?.map((ug) => ug?.group?.name || '') || [];
    if (intersection(groups, group_names).length === 0) {
      return false;
    }
  }

  if (excludeGroups.length) {
    const group_names =
      me?.users_groups?.map((ug) => ug?.group?.name || '') || [];
    if (intersection(excludeGroups, group_names).length > 0) {
      return false;
    }
  }

  if (feature_flags.length) {
    if (
      intersection(
        [FEATURE_FLAG.everything, ...feature_flags],
        me?.feature_flags as Prisma.JsonArray
      ).length === 0 &&
      intersection(
        [FEATURE_FLAG.everything, ...feature_flags],
        me?.organization?.feature_flags as Prisma.JsonArray
      ).length === 0
    ) {
      return false;
    }
  }

  if (user_access.length) {
    const user_access_names = me?.permissions?.map(
      (access) => access?.permissions?.name || ''
    );
    if ((me?.role as USER_ROLE) === 'OWNER') {
      return true;
    } else if (
      (me?.role as USER_ROLE) !== 'USER' &&
      user_access?.indexOf(USER_ACCESS_PERMISSIONS.settings) === -1
    ) {
      return true;
    } else {
      return intersection([...user_access], user_access_names || []).length > 0;
    }
  }

  if (all_feature_flags.length) {
    for (const product of all_feature_flags) {
      // They must have the product or feature flag and not have the none flag
      if (
        product !== FEATURE_FLAG.none &&
        intersection([product], me?.feature_flags as Prisma.JsonArray)
          .length === 0 &&
        intersection(
          [product],
          me?.organization?.feature_flags as Prisma.JsonArray
        ).length === 0
      ) {
        // They must have all persmissions and products
        return false;
      }
    }
    // If they get to here it means that have all the permissions
    return true;
  } else if (some_feature_flags.length) {
    for (const product of some_feature_flags) {
      // They must have the product or feature flag or have the none flag
      if (
        product === FEATURE_FLAG.none ||
        intersection([product], me?.feature_flags as Prisma.JsonArray).length ||
        intersection(
          [product],
          me?.organization?.feature_flags as Prisma.JsonArray
        ).length
      ) {
        return true;
      }
    }
    return false;
  }

  if (all_permissions.length) {
    for (const permission of all_permissions) {
      const product = USER_ACCESS_PERMISSIONS_FEATURE_FLAGS[permission];
      // They must have the product or feature flag and not have the none flag
      if (
        product !== FEATURE_FLAG.none &&
        intersection([product], me?.feature_flags as Prisma.JsonArray)
          .length === 0 &&
        intersection(
          [product],
          me?.organization?.feature_flags as Prisma.JsonArray
        ).length === 0
      ) {
        // They must have all persmissions and products
        return false;
      }
      if ((me?.role as USER_ROLE) !== 'OWNER') {
        // They are not OWNER now lets check if they have the permission
        const access = me?.permissions?.find(
          (access) => access?.permissions?.name === permission
        );
        if (!access) {
          return false;
        }
      }
    }
    // If they get to here it means that have all the permissions
    return true;
  } else if (some_permissions.length) {
    for (const permission of some_permissions) {
      const product = USER_ACCESS_PERMISSIONS_FEATURE_FLAGS[permission];
      // They must have the product or feature flag or have the none flag
      if (
        product === FEATURE_FLAG.none ||
        intersection([product], me?.feature_flags as Prisma.JsonArray).length ||
        intersection(
          [product],
          me?.organization?.feature_flags as Prisma.JsonArray
        ).length
      ) {
        if ((me?.role as USER_ROLE) === 'OWNER') {
          // They have the product and are an owner so they have the permission
          return true;
        }
        // They are not OWNER now lets check if they have the permission
        const access = me?.permissions?.find(
          (access) => access?.permissions?.name === permission
        );
        if (access) {
          return true;
        }
      }
    }
    return false;
  }

  if (!me.role) {
    return false;
  }

  // Check scopes
  if (scopes.length) {
    const permissions = PERMISSION[me.role];

    if (!permissions) {
      return false;
    }

    const scopesMap: { [key: string]: boolean } = {};
    scopes.forEach((scope) => {
      scopesMap[scope] = true;
    });

    const permissionGranted = permissions.some(
      (permission) => scopesMap[permission]
    );

    if (!permissionGranted) return false;
  }

  return true;
};

function intersection<T>(...arrays: T[][]): T[] {
  if (arrays.length === 0) return [];

  return arrays[0].filter((item) =>
    arrays.every((array) => array?.includes(item))
  );
}
